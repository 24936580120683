import {inject} from 'aurelia-framework';
import {DialogController, DialogService} from "aurelia-dialog";
import {TaskListItem} from "../../views/patient/pflegeplanung/taskListItem";
import {GMTCatalog} from "../../views/patient/pflegeplanung/GMTCatalog";
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";

@inject(Element, DialogController, DialogService, FhirService)
export class ModalTodoListProcedureRequestAdd {
    element: HTMLElement;
    controller: DialogController;
    dialogService: DialogService;
    fhirService: FhirService;

    search = "";
    requestGroups;
    codeSystem;
    catalog;

    items = {};

    concepts = {};

    constructor(element, dialogController, dialogService, fhirService) {
        this.element = element;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.fhirService = fhirService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    get hasSelectedItems() {
        return Object.values(this.items).filter(item => item).length > 0;
    }

    attached() {
        this.element.style.width = "85%";
        this.element.style.maxWidth = "900px";
    }

    async activate(data) {
        const that = this;

        this.requestGroups = data.requestGroups;
        this.codeSystem = data.codeSystem;
        this.catalog = [];

        this.filterCatalog();

        function parseConcepts(concepts) {
            for (let i = 0; i < concepts.length; i++) {
                const concept = concepts[i];
                const rootData = concept.code.split('.')[0];

                if (rootData == 100 || !concept.code || !concept.display) {
                    continue;
                }

                if (concept.concept) {
                    parseConcepts(concept.concept);
                } else {
                    that.concepts[concept.code] = concept;
                }
            }
        }

        parseConcepts(this.codeSystem.concept);
    }

    filterCatalog() {
        const that = this;

        function filterCodes(concepts) {
            const ctlg = [];

            for (let i = 0; i < concepts.length; i++) {
                const concept = concepts[i];
                const rootData = concept.code.split('.')[0];

                if (rootData == 100 || !concept.code || !concept.display || that.findCode(concept.code)) {
                    continue;
                }

                const item = {
                    _shown: false,
                    checked: false,
                    code: concept.code,
                    display: concept.display,
                    concept: []
                };

                if (concept.concept) {
                    item.concept = filterCodes(concept.concept);
                }

                if (((!concept.concept && (that.search.trim() === '' || concept.display.toLowerCase().indexOf(that.search.trim().toLowerCase()) > -1)) || item.concept.length > 0)) {
                    ctlg.push(item);
                }
            }

            return ctlg;
        }

        this.catalog = filterCodes(this.codeSystem.concept);
    }

    findCode(code) {
        for (let i = 0; i < this.requestGroups.length; i++) {
            const requestGroup = this.requestGroups[i];

            for (let j = 0; j < requestGroup.requests.length; j++) {
                const request = requestGroup.requests[j];

                if (request.codeSystem.code === code) {
                    for (let n = 0; n < request.procedureRequests.length; n++) {
                        const procedureRequest = request.procedureRequests[n].resource;

                        if (procedureRequest.intent !== fhirEnums.RequestIntent.instanceOrder) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    submit() {
        const legitimateItems = Object.keys(this.items).filter(key => this.items[key]);

        const items = [];
        for (let i = 0; i < legitimateItems.length; i++) {
            const item = legitimateItems[i];
            if (this.concepts[item]) {
                items.push(this.concepts[item]);
            }
        }

        if (items.length === 0) return;

        this.controller.ok(items);
    }

    close() {
        this.controller.cancel();
    }
}

