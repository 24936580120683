import {inject} from 'aurelia-framework';
import {CarePlanService} from "../services/CarePlanService";
import {DialogController} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";

@inject(CarePlanService, DialogController, I18N)
export class ModalDiagnosis {
    carePlanService: CarePlanService;
    dialogController: DialogController;
    i18n: I18N;

    diagnosisCodeSystem;
    conditions;

    modalHeight = 800;

    constructor(carePlanService: CarePlanService, dialogController: DialogController, i18n: I18N) {
        this.carePlanService = carePlanService;
        this.dialogController = dialogController;
        this.i18n = i18n;
    }

    async activate(model) {
        this.modalHeight = window.innerHeight * 0.7;

        this.conditions = JSON.parse(JSON.stringify(model.conditions));
        this.diagnosisCodeSystem = JSON.parse(JSON.stringify(await this.carePlanService.getDiagnoseCodeSystem(model.patient)));

        this.diagnosisCodeSystem.concept.forEach((group) => {
            group._isShown = false;

            if (!group.concept) {
                return;
            }

            group.concept.forEach((category) => {
                category._active = '';

                if (!category.concept) {
                    return;
                }

                category.concept.forEach((subCat) => {
                    this.conditions.forEach((condition) => {
                        const conditionCode = condition.code && condition.code.coding && condition.code.coding[0].code;

                        if (!conditionCode) {
                            return;
                        }

                        if (conditionCode === subCat.code) {
                            category._active = conditionCode;
                        }
                    });
                });
            });
        });
    }

    cancel() {
        this.dialogController.cancel();
    }

    submit() {
        const conditionCodes = [];

        this.diagnosisCodeSystem.concept.forEach((group) => {
            group.concept.forEach((category) => {
                if (category._active) {
                    const diagnose = category.concept.find((subCat) => subCat.code === category._active);

                    conditionCodes.push({
                        code: diagnose.code,
                        display: diagnose.display,
                        definition: diagnose.definition
                    });
                }
            });
        });

        this.dialogController.ok(conditionCodes);
    }

    changeStatus(category, item) {
        category._active = item ? item.code : '';
    }

    toggleExpand(group, node) {
        group._isShown = !group._isShown;

        if (!group._isShown) {
            node.classList.add('collapsed');
        } else {
            node.classList.remove('collapsed');
        }
    }
}
